import avatar from "../assets/avatar.png";
import testimonial1 from "../assets/testimonial1.jpg";
import testimonial2 from "../assets/testimonial2.jpg";

export const testimonials = [
  {
    post: "@5alive_fanatic",
    name: "UNALIVE",
    comment:
      "It is a very good application and will really love the web3 space to attend to this. It saved me from some airdrop scams. It's gonna be massive",
    image: testimonial2,
  },
  {
    post: "@Trisha_Mie3 - crypto enthusiast",
    name: "t r i s h a",
    comment:
      "I downloaded the extension, it surely warned me of potential phishing links. Awesome!",
    image: testimonial1,
  },
];
